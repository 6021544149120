import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

import { QuizAttribute } from 'components/constants';
import { Check, Cross } from 'components/icons';
import { IconStyles } from 'components/icons/types';
import { getStylesForButtonStates } from 'lib/styles/inputStateStyles';

import { GenericInputButtonProps } from './types';

const flatten = StyleSheet.flatten;

export const GenericInputIcon = ({
  quiz,
  style,
  size,
}: GenericInputButtonProps & {
  style: IconStyles;
  size: number;
}): ReactElement | null => {
  const styleWithColor = Object.assign(
    {
      color: getStylesForButtonStates('color')({ isActive: true }),
    },
    flatten(style) as IconStyles
  );

  return quiz === QuizAttribute.CORRECT ? (
    <Check style={styleWithColor} size={size} />
  ) : quiz === QuizAttribute.INCORRECT ? (
    <Cross style={styleWithColor} size={size} />
  ) : null;
};
