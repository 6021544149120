import { TextStyle, ViewStyle } from 'react-native';

import { Scene } from '@bighealth/types/dist/scene-component';

const justifyContentConstants = [
  'center',
  'flex-start',
  'flex-end',
  'space-between',
  'space-around',
  'space-evenly',
];

const isJustifyContent = (str?: string): str is ViewStyle['justifyContent'] => {
  if (typeof str !== 'undefined' && justifyContentConstants.includes(str)) {
    return true;
  }
  return false;
};

export const getFontWeight = (
  fontWeight: Scene.Presentational.StyleObject['fontWeight']
): TextStyle['fontWeight'] => {
  if (fontWeight === 200) {
    return '200';
  } else if (fontWeight === 300) {
    return '300';
  } else if (fontWeight === 400) {
    return '400';
  } else if (fontWeight === 500) {
    return '500';
  } else if (fontWeight === 600) {
    return '600';
  } else if (fontWeight === 700) {
    return '700';
  } else if (fontWeight === 800) {
    return '800';
  } else if (fontWeight === 900) {
    return '900';
  } else {
    return undefined;
  }
};

export const convertToNativeStyle = (
  style?: Scene.Presentational.StyleObject
): ViewStyle & TextStyle => {
  if (typeof style === 'undefined') {
    return {};
  }
  const {
    flexGrow,
    flexWrap,
    alignSelf,
    fontWeight,
    alignItems,
    flexDirection,
    justifyContent,
    ...restOfStyles
  } = style;

  const supportedStyles: ViewStyle & TextStyle = restOfStyles as Record<
    string,
    unknown
  >;

  if (
    typeof alignItems !== 'undefined' &&
    alignItems !== 'inherit' &&
    alignItems !== 'initial'
  ) {
    supportedStyles.alignItems = alignItems;
  }

  if (
    typeof alignSelf !== 'undefined' &&
    alignSelf !== 'inherit' &&
    alignSelf !== 'initial'
  ) {
    supportedStyles.alignSelf = alignSelf;
  }

  if (
    typeof flexDirection !== 'undefined' &&
    flexDirection !== 'unset' &&
    flexDirection !== 'initial'
  ) {
    supportedStyles.flexDirection = flexDirection;
  }

  if (
    typeof flexWrap !== 'undefined' &&
    flexWrap !== 'inherit' &&
    flexWrap !== 'initial'
  ) {
    supportedStyles.flexWrap = flexWrap;
  }

  if (isJustifyContent(justifyContent)) {
    supportedStyles.justifyContent = justifyContent;
  }

  if (typeof flexGrow !== 'undefined' && typeof flexGrow !== 'string') {
    supportedStyles.flexGrow = flexGrow;
  }

  if (typeof fontWeight !== 'undefined') {
    supportedStyles.fontWeight = getFontWeight(fontWeight);
  }

  return supportedStyles;
};
