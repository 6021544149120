import { ReactNode } from 'react';
import { DimensionValue, TextStyle, ViewStyle } from 'react-native';

import { Accessibility } from '@bighealth/types';
import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { buttonColorSchemes } from 'components/UniveralButtons/buttonColorSchemes';

export type ButtonDimensions = {
  paddingTop: DimensionValue;
  paddingLeft: DimensionValue;
  paddingBottom: DimensionValue;
  paddingRight: DimensionValue;
  borderRadius: number;
  minWidth: number;
  lineHeight: number;
  fontSize: number;
  minHeight: number;
  borderWidth: number;
};

// Strictly we do allow content creators to create other styles, but we'll restrict it
// here just to make the types a little easier to handle.
export type NumericalCSSStyles = {
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
};

export type ButtonTextStyles = {
  fontWeight?: TextStyle['fontWeight'];
};

export type ButtonStyles = {
  color: string;
  backgroundColor: string;
} & ButtonDimensions;

export type InteractionHandler = () => void;

export enum ButtonSizes {
  Small = 'small',
  Mini = 'mini', // 29Sep2022: only used in test data
  LegacySecondary = 'SECONDARY_BUTTON', // 29Sep2022: only used in test data
  SmallText = 'small_text', // 29Sep2022: only used in test data
}

export type ButtonProps = {
  testID?: string; // This gets converted to `data-testid` for you on web
  action?: SceneAction;
  align?: string;
  isDisabled?: boolean;
  onPress?: () => void;
  style?: ViewStyle & {
    fontSize?: number;
    lineHeight?: number;
    fontWeight?: TextStyle['fontWeight'];
  };
  text: ReactNode;
  size?: ButtonSizes;
  colorScheme?: keyof typeof buttonColorSchemes;
  accessibility?: Accessibility;
  textStyleProps?: ViewStyle & {
    textDecorationLine?:
      | 'none'
      | 'underline'
      | 'line-through'
      | 'underline line-through'
      | undefined;
    fontSize?: number;
  };
};

export type ButtonInteractionColors = {
  color: string;
  backgroundColor: string;
  borderColor: string;
};

export type ButtonInteractionColorScheme = {
  disabled: ButtonInteractionColors;
  pressed: ButtonInteractionColors;
  default: ButtonInteractionColors;
};
