import { isDevMode } from 'lib/isDevMode';
import { stringify } from 'lib/stringify';

const flagStore: Record<string, boolean> = {};

/**
 * Init config flag options for QA Menu
 *
 * @param {string[]} flagNames List of Flag names that QA team can toggle on/off
 * @returns list of UI props for Listing/Toggling flags
 */
const init = (
  flagNames: string[]
): Array<{ text: string; onPress: () => void }> => {
  // Ensure flagStore has values
  flagNames.forEach(flagName => {
    flagStore[flagName] = !!flagStore[flagName];
  });
  // build ui props
  return flagNames.map(flagName => ({
    text: `${flagStore[flagName] ? '▣' : '◻'} ${flagName}`,
    onPress: () => {
      flagStore[flagName] = !flagStore[flagName]; // TOGGLE
    },
  }));
};

/**
 * Used throughout the app, to enable/disable debug functionality for QA team
 *
 * @param flagName Each flag is added to the debug menu, and QA can toggle them on or off
 * @returns value for flag
 */
const getValue = (flagName: string): boolean => {
  if (!isDevMode()) {
    return false;
  }
  const value = flagStore[flagName];
  /* eslint-disable-next-line no-constant-condition */
  if (typeof value !== 'boolean' && false) {
    throw Error(
      `qaConfigFlags can't find boolean with flag "${flagName}" in: ${stringify(
        flagStore
      )}, flags must be manually added to src/lib/showQAMenu/getQAOptions.tsx to appear in menu`
    );
  }
  return flagStore[flagName] || false;
};

export const qaConfigFlags = { init, getValue };
