import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { StyleObject } from '@bighealth/types/dist/scene-components/client';

import { useTransformStylesToContext } from 'components/ResponsiveLayout/hooks/useTransformStylesToContext';
import { DEFAULT_THEME, ThemeProp } from 'config/getThemeForProducts';
import { assertUnreachable } from 'lib/type-guarded';

import {
  ButtonDimensions,
  ButtonProps,
  ButtonSizes,
  ButtonTextStyles,
  NumericalCSSStyles,
} from './types';

type CombinedStyles = NumericalCSSStyles & ButtonDimensions & ButtonTextStyles;

export const useDynamicButtonDimensions = ({
  size = ButtonSizes.LegacySecondary,
  style = {},
  theme = DEFAULT_THEME,
}: ButtonProps & ThemeProp): CombinedStyles => {
  const transformStyles = useTransformStylesToContext(theme);
  let unscaledDimensions: ButtonDimensions;
  const flattedStyle = StyleSheet.flatten(style);

  switch (size) {
    case ButtonSizes.LegacySecondary: // 29Sep2022: only used in test data
    case ButtonSizes.Small:
      unscaledDimensions = theme.buttons?.dimensions[ButtonSizes.Small] || {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
        minWidth: 220,
        borderRadius: 32,
        fontSize: 20,
        lineHeight: 24,
        minHeight: 24 + 16 + 16,
        borderWidth: 2,
        // Spreading after to allow Content Creators to customize
        ...(flattedStyle as Record<string, unknown>),
      };
      break;
    case ButtonSizes.Mini: // used for now only for progress review widget Start button
      unscaledDimensions = {
        paddingTop: 6,
        paddingLeft: 16,
        paddingBottom: 6,
        paddingRight: 16,
        minHeight: 40,
        minWidth: 72,
        borderRadius: 32,
        fontSize: 20,
        lineHeight: 24,
        borderWidth: 2,
        // Spreading after to allow Content Creators to customize
        ...(flattedStyle as Record<string, unknown>),
      };
      break;
    case ButtonSizes.SmallText: // 29Sep2022: only used in test data
      unscaledDimensions = {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        paddingRight: 0,
        minHeight: 24,
        minWidth: 104,
        borderRadius: 0,
        fontSize: 20,
        lineHeight: 24,
        borderWidth: 0,
        // Spreading after to allow Content Creators to customize
        ...(flattedStyle as Record<string, unknown>),
      };
      break;
    default:
      assertUnreachable(size);
  }

  // @TODO: Remove this `as`
  // @WHEN: no rush as it's internal but the reason we need it is due to mixing up
  // ViewStyle, StyleProp<ViewProps> and our own definitions in various places.
  const scaledDimensions = transformStyles(
    unscaledDimensions as StyleObject
  ) as ButtonDimensions; // Button is never used in Absolute mode so we'll just assert ButtonDimensions here

  return useMemo(() => scaledDimensions as CombinedStyles, [scaledDimensions]);
};
