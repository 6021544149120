import React, { ReactElement, ReactNode, useContext } from 'react';
import { DimensionValue, View } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { Scene } from '@bighealth/types/src/scene-component';

import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { useTransformContentStyle } from 'daylight/hooks';

export const PADDING_PROGRESS_CARD = 20;

export type ProgressText = {
  text: string;
  color: string;
  fontSize: number;
};

export type ProgressCardType = {
  upperComponent?: ReactNode;
  lowerText?: Scene.Components.Text;
  boldText?: Scene.Components.Text;
  width?: DimensionValue;
  padding?: number;
  children?: ReactNode;
};

export const ProgressCard = ({
  upperComponent,
  lowerText,
  boldText,
  width,
  padding,
  children,
}: ProgressCardType): ReactElement => {
  const theme = useContext(ThemeContext);
  const transformStyle = useTransformContentStyle();

  return (
    <View
      {...roles('progressCard')}
      style={{
        backgroundColor: 'white',
        width: width || '100%',
        padding: padding || transformStyle(PADDING_PROGRESS_CARD),
        borderRadius: 9,
      }}
    >
      {typeof upperComponent !== 'undefined' ? upperComponent : null}

      {lowerText && lowerText.text !== '' ? (
        // NOTE (2021-12-09): currently Scene.Components.Text doesn't support text within text and we
        // need to use the more abstract Text component.
        // If no size or color values are defined, we fallback to theme values
        // TODO: migrate the text size into the themes
        <Text>
          <Text
            style={{
              fontSize: lowerText.style?.fontSize || transformStyle(18),
              color: lowerText.style?.color || theme.color.text.primary,
            }}
          >
            {lowerText.text}
          </Text>
          {boldText ? (
            <Text
              style={{
                fontSize: boldText.style?.fontSize || transformStyle(18),
                color: boldText.style?.color || theme.color.text.primary,
                fontWeight: '700',
              }}
            >
              {boldText.text}
            </Text>
          ) : null}
        </Text>
      ) : null}
      {children}
    </View>
  );
};
