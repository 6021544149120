import { DimensionValue, StyleProp, View, ViewStyle } from 'react-native';
import { CSSProperties } from 'styled-components';
import styled from 'styled-components/native';

import { getTheme } from 'lib/styles/getTheme';

export const Wrapper = View;

const MIN_SLIVER_VALUE = 0.5; // Always show a "sliver" single color = 100%
const BAR_HEIGHT = 7;
const BORDER_RADIUS = 12.34;

// Utils
function getCssPropertyFromViewProp<T>(
  value: T | undefined,
  defualtValue: T
): T {
  return typeof value === 'undefined' || value === null ? defualtValue : value; // string = percent
}

export const getWidth = (props: {
  style: {
    width?: ViewStyle['width'];
  };
}): DimensionValue => getCssPropertyFromViewProp(props.style['width'], `100%`);

export const getHeight = (props: {
  style: {
    height?: ViewStyle['height'];
  };
}): DimensionValue =>
  getCssPropertyFromViewProp(props.style['height'], BAR_HEIGHT);

export const getBorderRadius = (props: {
  style: {
    borderRadius?: ViewStyle['borderRadius'];
  };
}): number =>
  getCssPropertyFromViewProp(
    props.style['borderRadius'],
    BORDER_RADIUS
  ) as number;

// Range
type RangeProps = {
  style: {
    width?: ViewStyle['width'];
    height?: ViewStyle['height'];
    borderRadius?: ViewStyle['borderRadius'];
  } & StyleProp<ViewStyle>;
};

export const Range = styled.View<RangeProps>`
  position: absolute;
  width: 100%;
  background-color: ${getTheme('content.controls.player.progressColorDim')};
  border-radius: ${getBorderRadius};
`;
Range.displayName = 'ProgressBar.Range';

// Value
interface ValueProps {
  max: number;
  min: number;
  value: number;
  style: {
    height?: ViewStyle['height'];
    borderRadius?: ViewStyle['borderRadius'];
  } & StyleProp<ViewStyle>;
}
export const getPercent = ({
  max,
  min,
  value,
}: ValueProps): CSSProperties['width'] => {
  const numerator = 100 * (value - min);
  const denominator = max - min;
  return `${Math.max(numerator / denominator, MIN_SLIVER_VALUE)}%`;
};

export const Value = styled.View<ValueProps>`
  width: ${getPercent};
  background-color: ${getTheme('content.controls.player.progressColor')};
  border-bottom-left-radius: ${getBorderRadius};
  border-top-left-radius: ${getBorderRadius};
`;
Range.displayName = 'ProgressBar.Value';
